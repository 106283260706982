<template>
  <div class="dashboard">
    <div class="text-center py-4" v-if="items.length == 0">
      <img
        class="dashboard__img d-block mx-auto"
        width="500px"
        :src="require('@/assets/images/complete.png')"
        alt=""
      />
      <span class="font-20 d-block mt-3">{{
        $t("dashboard.noDoctorsYet")
      }}</span>
      <div
        class="mt-3 radius-10 py-3 d-flex align-center justify-center cursor-pointer primary-outlined hover-btn-outlined mx-auto"
        style="width: 180px"
        @click="$router.go(-1)"
      >
        <span class="font-700 font-18 register-primary--text"
          >{{ $t('dashboard.back') }}</span
        >
      </div>
    </div>
    <v-data-table
      v-if="items.length > 0"
      :headers="headers"
      :items="items"
      hide-default-footer
    >
      <!-- name & image -->
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center pl-3">
          <img
            class="object-cover radius-10 circle"
            width="50px"
            height="50px"
            :src="require('@/assets/images/logo-mini.png')"
          />
          <!-- :src="item.profile" -->

          <div class="ml-4 mt-5 mb-5">
            <span class="font-21 font-600 d-block darkbord--text">{{
              item.first_name
            }}</span>
            <span class="font-16 font-600 d-block bluetextdash--text"
              >{{ $t("forms.gender") }}: {{ item.gender }}</span
            >
            <span class="font-16 font-600 d-block bluetextdash--text"
              >{{ $t("forms.nationality") }}: {{ item.nationality }}</span
            >
            <span class="font-16 font-600 d-block bluetextdash--text"
              >{{ $t("forms.jobType") }}: {{ item.job_type }}</span
            >

            <span class="font-16 font-600 d-block bluetextdash--text"
              >{{ $t("forms.specialities") }}: {{ item.specialization }}</span
            >
          </div>
        </div>
      </template>

      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-btn
            color="#C5FFF5"
            dark
            elevation="0"
            @click="viewDoctorAssign(item)"
          >
            <span class="font-16 darkprimary--text">{{
              $t("dashboard.viewDetails")
            }}</span>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
    headers: [
      { text: "Name", value: "name", sortable: false, width: "85%" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
  }),
  methods: {
    async getData() {
      let { data } = await this.$axios.post("orders/doctors", {
        order_id: this.$route.params.id,
      });
      if (data.success) {
        this.items = data.data;
      }
    },
    viewDoctorAssign(item) {
      this.$store.dispatch("setDoctorInfo", item);
      this.$router.push(
        `/dashboard/order-assign/${this.$route.params.id}/doctors/${item.id}`
      );
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.dashboard {
  border-radius: 15px;
  background: white;
  padding: 8px;

  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }
  thead {
    display: none;
  }
  td,
  th {
    border-bottom: none !important;
  }
  tr {
    height: 80px;
  }
  @media (max-width: 960px) {
    padding: 0px;
    &__img {
      max-width: 300px;
    }
    tr {
      height: auto;
    }
  }
}
</style>
